import { type Cohort } from 'Cohorts';

import { getCohort, type CurrentUserIguanaObject } from 'Users';

type UpfrontPaymentRegistrationTest = {
    isRegistrationTest: boolean;
    showAsButtons: boolean;
    showShareProfileCheckbox: boolean;
    monthlyPlanDescriptionLocale: string;
    annualPlanDescriptionLocale: string;
    upFrontPlanDescriptionLocale: string;
    tuitionPlanDetailsLocale: string;
};

const defaultConfig: UpfrontPaymentRegistrationTest = {
    isRegistrationTest: false,
    showAsButtons: false,
    showShareProfileCheckbox: true,
    monthlyPlanDescriptionLocale: 'settings.tuition_and_registration.monthly_plan_desc',
    annualPlanDescriptionLocale: 'settings.tuition_and_registration.annual_plan_desc',
    upFrontPlanDescriptionLocale: 'settings.tuition_and_registration.single_payment_plan_desc',
    tuitionPlanDetailsLocale: 'settings.tuition_and_registration.tuition_plan_detail',
};

const textOnlyConfig: UpfrontPaymentRegistrationTest = {
    isRegistrationTest: true,
    showAsButtons: false,
    showShareProfileCheckbox: false,
    monthlyPlanDescriptionLocale: 'settings.tuition_and_registration.pay_monthly',
    annualPlanDescriptionLocale: 'settings.tuition_and_registration.twice_and_save',
    upFrontPlanDescriptionLocale: 'settings.tuition_and_registration.upfront_and_save',
    tuitionPlanDetailsLocale: 'settings.tuition_and_registration.tuition_plan_detail_b',
};

const buttonStyleConfig: UpfrontPaymentRegistrationTest = {
    ...textOnlyConfig,
    showAsButtons: true,
};

const isRound60DegreeProgram = (cohort: Cohort) => /[ALM]60$/.test(cohort.name);

export const getUpfrontPaymentRegistrationTest = (user: CurrentUserIguanaObject | null) => {
    const cohort = getCohort(user);

    if (!cohort || !user) return defaultConfig;

    if (isRound60DegreeProgram(cohort)) {
        if (/^[0-2]/.test(user.id)) return textOnlyConfig;
        if (/^[3-5]/.test(user.id)) return buttonStyleConfig;
    }

    return defaultConfig;
};
