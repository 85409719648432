import { type FrontRoyalStoreDB, type StudentDashboardContentFetchKeyParams } from 'FrontRoyalStore';

export default async function studentDashboardIsFetchedForUser(
    params: StudentDashboardContentFetchKeyParams,
    db: FrontRoyalStoreDB,
) {
    // If there is a record in studentDashboardContentFetches table for this user,
    // that means that we have fetched the student dashboard for them in the past.
    const studentDashboardFetch = await db.studentDashboardContentFetches
        .where({
            user_id: params.userId,
            cohort_id: params.cohortId,
            cohort_version_id: params.cohortVersionId,
            pref_locale: params.prefLocale,
            content_views_refresh_updated_at: params.contentViewsRefreshUpdatedAt,
        })
        .first();

    return !!studentDashboardFetch;
}
