import 'SafeApply/angularModule';
import 'guid/angularModule';
import Sortable from 'sortablejs';

// inspired by: http://amnah.net/2014/02/18/how-to-set-up-sortable-in-angularjs-without-jquery/
// dependent upon: http://rubaxa.github.io/Sortable/
// example usage: '<ul sortable="lesson.frames"><li ng-repeat="item in listItems" data-index="{{$index}}"></li><ul>'
export default angular.module('sortable', ['safeApply', 'guid']).directive('sortable', [
    '$injector',
    $injector => {
        const $timeout = $injector.get('$timeout');
        const guid = $injector.get('guid');
        const safeApply = $injector.get('safeApply');

        // sort by indexes
        function getSorted(arr, sortArr) {
            const result = [];
            for (let i = 0; i < arr.length; i++) {
                result[i] = arr[sortArr[i]];
            }
            return result;
        }

        // render the sortable list with callback options
        function renderSortable(scope, element) {
            const htmlElem = element.get(0);

            const sortableOptions = {
                onUpdate() {
                    // get new sort order based on indexes ('data-index')
                    const newSortIndexes = [];
                    const children = $(htmlElem).find('[data-index]');
                    for (let i = 0; i < children.length; i++) {
                        newSortIndexes.push(children[i].getAttribute('data-index'));
                    }

                    // process change using mapped sort indexes for value lookup
                    scope.newSortIndexes = newSortIndexes;
                    scope.collection = getSorted(scope.collection, newSortIndexes);

                    // changing this key on every re-order allows us to efficiently
                    // watch for the underlying collection to be re-ordered.  See image_selector_dir.js
                    scope.collection.$$sortableKey = guid.generate();
                    safeApply(scope);
                },
            };

            if (scope.handle) {
                sortableOptions.handle = scope.handle;
            }

            if (scope.animation) {
                sortableOptions.animation = scope.animation;
            }

            scope.sortableHandler = new Sortable(htmlElem, sortableOptions);
        }

        return {
            restrict: 'A',
            scope: {
                collection: '=',
                handle: '@?',
                animation: '@?',
            },
            link(scope, element) {
                scope.$watch('collection', collection => {
                    if (collection) {
                        collection.$$sortableKey = guid.generate();
                    }
                });

                // watch for changes to underlying HTML contents and re-render
                scope.$watch(
                    () => element.html(),
                    (newVal, oldVal) => {
                        if (newVal === oldVal) {
                            return;
                        }

                        $timeout(() => {
                            if (!scope.sortableHandler) {
                                renderSortable(scope, element);
                            }
                        }, 0);
                    },
                );

                // cleanup on scope destroy
                scope.$on('$destroy', () => {
                    if (scope.sortableHandler) {
                        scope.sortableHandler.destroy();
                        scope.sortableHandler = undefined;
                    }
                });
            },
        };
    },
]);
