import { uniq } from 'lodash/fp';
import delay from 'delay';
import { type LessonIguanaObject } from 'Lessons';

async function recursivelyGetImageUrls(lesson: LessonIguanaObject<true>) {
    const results: string[] = [];

    // FIXME: these awaits inside of a loop are awkward, but
    // necessary.  We need to throttle things to keep from
    // locking up the browser. We also `delay(0)` as our underlying
    // `recursivelyGetImageUrls` logic resolves with `$q.when`
    // For more info, see https://trello.com/c/5yQMSVoI
    for (let i = 0; i < lesson.frames.length; i += 1) {
        const frame = lesson.frames[i].reify();
        // eslint-disable-next-line no-await-in-loop
        const result = await frame.recursivelyGetImageUrls();
        if (result) results.push(...result);
        // eslint-disable-next-line no-await-in-loop
        await delay(0);
    }

    return results;
}

export default async function getImageUrlsForLesson(
    // FIXME:  it would be nice to do this without componentized iguana models, but
    // it seemed like it would require quite a bit of refactoring.
    lesson: LessonIguanaObject<true>,
) {
    // FIXME:  it would be nice to do this without componentized iguana models, but
    // it seemed like it would require quite a bit of refactoring.
    const urls = await recursivelyGetImageUrls(lesson);
    const uniqUrls = uniq(urls);
    return uniqUrls;
}

export { getImageUrlsForLesson };
