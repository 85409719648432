import { deriveActivePlaylist } from 'StudentDashboard';
import { getStreamPackIdsInCurriculumOrder } from 'Program';
import { type CurrentUserIguanaObject } from 'Users';
import { type LearnerContentCache as LearnerContentCacheType } from 'LearnerContentCache';
import { type StreamIguanaObject, type StreamIguanaClass, type ContentAccessHelperClass } from 'Lessons';
import { type AnyObject } from '@Types';
import getActiveStream from './getActiveStream';
import getLocalePackIdsForCompletedStreams from './getLocalePackIdsForCompletedStreams';
import getLocalePackIdsForUncompletedStreams from './getLocalePackIdsForUncompletedStreams';

// Returns the next n stream locale pack ids in curriculum order after the
// given startStreamPackId. If no startStreamPackId is given, it returns
// the first n stream locale pack ids from the start of the curriculum.
// If the startStreamPackId refers to a stream in a specialization, then we're going
// to get the next N streams in that specialization, or if we run out, some streams from
// the next specializations. This probably isn't the ideal thing, but it's good enough for now.
// getNextNRelativeStreamPackIds(playlists, startStreamPackId, nStreamPackIds) {
//     const streamPackIdsInCurriculumOrder = this.getStreamPackIdsInCurriculumOrder(playlists);
// },
function getNextNRelativeStreamPackIds(
    streamPackIdsInCurriculumOrder: string[],
    startStreamPackId: string | undefined,
) {
    const nStreamPackIds = 5;

    const startStreamPackIdIndex =
        startStreamPackId &&
        streamPackIdsInCurriculumOrder.findIndex(streamPackId => streamPackId === startStreamPackId);
    const startIndex = !startStreamPackIdIndex || Number.isNaN(startStreamPackIdIndex) ? 0 : startStreamPackIdIndex + 1;
    return streamPackIdsInCurriculumOrder.slice(startIndex, startIndex + nStreamPackIds);
}

/*
    Content is stored in the following priority order:
        1. The course the user is currently studying.
        2. The next recommended course (i.e. the keep learning stream)
        3. The currently active playlist, if it exists
        4. The next 5 courses in the schedule, if it exists, that come after the stream they're currently studying
        5. Any incomplete/unstarted courses
        6. Any completed courses

    NOTE: We don't want to allow students to take exams in offline mode,
    so we exclude them from the list of streams below that we store for offline use.
*/
export default async function getLocalePackIdsForStreamsToStore(
    injector: angular.auto.IInjectorService,
    user: CurrentUserIguanaObject,
) {
    // NOTE: `LearnerContentCache.ensureStudentDashboard` returns a $q promise, but we
    // can get away with that here because we're just firing off requests to load streams
    // in the background and we're not immediately updating anything in the UI.
    const LearnerContentCache = injector.get<LearnerContentCacheType>('LearnerContentCache');
    const ContentAccessHelper = injector.get<ContentAccessHelperClass>('ContentAccessHelper');
    const studentDashboardResponse = await LearnerContentCache.ensureStudentDashboard();
    const streams = studentDashboardResponse.result[0].lesson_streams;
    const playlists = studentDashboardResponse.result[0].available_playlists;
    const streamPackIdsInCurriculumOrder = getStreamPackIdsInCurriculumOrder(user, playlists);

    // (1) The "active" stream refers to the stream the user is currently studying.
    const activeStream = await getActiveStream(injector, user);

    // (3)
    const activePlaylist = deriveActivePlaylist(playlists, user);

    // (2) NOTE: The "Keep Learning" stream is derived from the active playlist,
    // so this logic comes after the logic for deriving the active playlist.
    const keepLearningStream = injector
        .get<StreamIguanaClass>('Lesson.Stream')
        .keepLearningStream(activePlaylist, streams);

    // (4)
    const nextNStreamPackIdsAfterActiveStream = getNextNRelativeStreamPackIds(
        streamPackIdsInCurriculumOrder,
        activeStream?.locale_pack?.id,
    );

    // (5)
    const uncompletedStreamPackIds = await getLocalePackIdsForUncompletedStreams(
        injector,
        user,
        streamPackIdsInCurriculumOrder,
    );

    // (6)
    const completedStreamPackIds = await getLocalePackIdsForCompletedStreams(injector, user);

    // make sure that we don't include any streams in the result set that are not allowed to be taken offline (exams)
    const disallowedOfflineStreams = streams
        .filter(stream => !ContentAccessHelper.allowedOffline(stream))
        .reduce<AnyObject<StreamIguanaObject>>((obj, stream) => ({ ...obj, [stream.locale_pack.id]: stream }), {});

    const streamPackIdsToStore = [
        activeStream?.locale_pack?.id,
        keepLearningStream?.locale_pack?.id,
        activePlaylist?.streamLocalePackIds,
        nextNStreamPackIdsAfterActiveStream,
        uncompletedStreamPackIds,
        completedStreamPackIds,
    ]
        .flat()
        .filter(streamPackId => streamPackId && !disallowedOfflineStreams[streamPackId]);
    return [...new Set(streamPackIdsToStore)];
}
