export default angular.module('SortableColumns', []).factory('HasSortableColumnsMixin', [
    () => ({
        onLink(scope) {
            scope.sort = {
                column: '',
                descending: false,
            };

            scope.changeSorting = column => {
                const sort = scope.sort;

                if (sort.column === column) {
                    sort.descending = !sort.descending;
                } else {
                    sort.column = column;
                    sort.descending = false;
                }
            };

            scope.sortClasses = column => [
                column.replace('.', '-'),
                column === scope.sort.column ? `sort-${scope.sort.descending ? 'desc' : 'asc'}` : '',
            ];

            // Special wrappers for TabularReports
            // Note: assumes a scope with a currentReport variable that can be used for lookups
            scope.changeTabularSorting = column => {
                const columnIndex = scope.currentReport.INDEXES_FOR_COLUMNS[column];
                scope.changeSorting(String(columnIndex));
            };

            scope.tabularSortClasses = column => {
                const columnIndex = scope.currentReport.INDEXES_FOR_COLUMNS[column];
                return scope.sortClasses(String(columnIndex));
            };
        },
    }),
]);
