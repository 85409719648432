import { useEffect, useRef, useState, type CSSProperties } from 'react';
import {
    type ProgramAchievementGraphicKey,
    type ProgramAchievementGraphicDesignKey,
} from 'ShareableGraphics/ShareableGraphics.types';
import { fetchBrandConfig } from 'AppBranding';
import { programAchievementGraphicDesigns } from 'ShareableGraphics/programAchievementGraphicDesigns';
import ClientStorage from 'ClientStorage';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import FrontRoyalSpinner from 'FrontRoyalSpinner';
import parseHtml from 'html-react-parser';
import { type AnyObject } from '@Types';
import { downloadOrShareSVG } from 'ShareableGraphics/helpers/helpers';
import { downloadSupported, shareSupported } from 'ShareableGraphics/helpers/downloadHelpers';
import { fontConfigs, SVG_VIEWBOX_HEIGHT, SVG_VIEWBOX_WIDTH } from 'ShareableGraphics/constants';
import { type CurrentUserIguanaObject } from 'Users';

export function ProgramAchievementGraphic({
    variant,
    collapsed,
    currentUser,
    xsOrSm,
}: {
    variant: ProgramAchievementGraphicKey;
    collapsed: boolean;
    currentUser: CurrentUserIguanaObject;
    xsOrSm: boolean;
}) {
    const { t } = useTranslation('back_royal');

    const brandConfig = currentUser?.relevantCohort ? fetchBrandConfig(currentUser.relevantCohort.branding) : null;
    const graphicsConfig = brandConfig?.programAchievementGraphics;

    const containerRef = useRef<HTMLDivElement>(null);

    const clientStorageDesignKey = `program_achievement_graphic_${variant}_design`;
    const storedDesignKey = ClientStorage.getItem(clientStorageDesignKey);
    const graphicConfig = graphicsConfig?.[variant];

    const availableDesignKeys = graphicConfig?.availableDesigns;
    const defaultSelected = availableDesignKeys?.[0];
    const initialSelected = availableDesignKeys?.includes?.(storedDesignKey) ? storedDesignKey : defaultSelected;

    const [selectedDesignKey, setSelectedDesignKey] = useState<ProgramAchievementGraphicDesignKey>(initialSelected);
    const [hoveredDesignKeys, setHoveredDesignKeys] = useState<AnyObject<boolean>>();
    const [shareIsSupported, setShareIsSupported] = useState(false);
    const [downloading, setDownloading] = useState(false);

    const showDownload = downloadSupported() || shareIsSupported;
    const selectedDesign = programAchievementGraphicDesigns[selectedDesignKey];

    useEffect(() => {
        ClientStorage.setItem(clientStorageDesignKey, selectedDesignKey);
    }, [clientStorageDesignKey, selectedDesignKey]);

    useEffect(() => {
        if (defaultSelected && !availableDesignKeys?.includes?.(selectedDesignKey)) {
            setSelectedDesignKey(defaultSelected!);
        }
    }, [availableDesignKeys, selectedDesignKey, setSelectedDesignKey, defaultSelected]);

    useEffect(() => {
        shareSupported().then(setShareIsSupported);
    }, [setShareIsSupported]);

    const getDesignBackgroundStyle = (designKey: ProgramAchievementGraphicDesignKey): CSSProperties => {
        const design = programAchievementGraphicDesigns[designKey];
        const background = `linear-gradient(-20deg, ${design.bgGradient.startColor.hex}, ${design.bgGradient.endColor.hex})`;
        return { background };
    };

    // We only apply a background style for the surrounding background at xs/sm screen sizes.
    const getSurroundingBackgroundStyle = () => (xsOrSm ? getDesignBackgroundStyle(selectedDesignKey) : undefined);

    const getDesignBubbleStyles = (designKey: ProgramAchievementGraphicDesignKey) => {
        const design = programAchievementGraphicDesigns[designKey];
        const styles = getDesignBackgroundStyle(designKey);
        const isSelected = selectedDesignKey === designKey;
        const isHovered = !!hoveredDesignKeys?.[designKey];
        if (isSelected || isHovered) {
            // When the design bubble is selected, we add a ring around the design bubble.
            // Similarly, if the mouse is hovering over a design bubble, then we also add
            // the ring around the design bubble, but fade it a bit. If the mouse is hovering
            // over the design bubble belonging to the selectedDesign, then we don't bother
            // fading the ring.
            const { r, g, b } = design.ringRGBA;
            styles.boxShadow = `0 0 0 4px rgba(${r}, ${g}, ${b}, ${isSelected ? 1 : 0.6})`;
        } else {
            styles.boxShadow = 'none';
        }
        return styles;
    };

    const handleDesignClick = (designKey: ProgramAchievementGraphicDesignKey) => {
        setSelectedDesignKey(designKey);
        ClientStorage.setItem(storedDesignKey, designKey);
    };

    const handleDesignHover = (designKey: ProgramAchievementGraphicDesignKey, hovering: boolean) => {
        setHoveredDesignKeys(val => ({
            ...val,
            [designKey]: hovering,
        }));
    };

    const handleDownloadClick = () => {
        if (!showDownload || downloading) return;

        downloadOrShareSVG({
            setDownloading,
            containerElem: containerRef.current!,
            fileName: `${currentUser.name}-${variant}-image-${selectedDesignKey}`,
            width: SVG_VIEWBOX_WIDTH * 2,
            height: SVG_VIEWBOX_HEIGHT * 2,
            fontConfigs,
        });
    };

    if (!selectedDesign || !graphicConfig || !brandConfig) return null;

    return (
        <div
            ref={containerRef}
            key={variant}
            className={clsx(
                'program-achievement-graphic box-contents max-md:tw-min-h-full max-md:tw-w-full max-md:tw-py-8',
                variant,
                selectedDesignKey,
            )}
            data-testid="program-achievement-graphic"
            style={getSurroundingBackgroundStyle()}
        >
            {collapsed && <div className="collapsed-container tw-invisible tw-h-full" />}
            {!collapsed && (
                <div className="expanded-container max-md:tw-mx-auto max-md:tw-w-11/12 max-md:tw-max-w-[350px] max-md:tw-shadow-[0px_8px_11px_rgba(0,0,0,0.5)]">
                    <div className="main-box">
                        <graphicConfig.component
                            selectedDesign={selectedDesign}
                            brandConfig={brandConfig}
                            currentUser={currentUser}
                        />
                    </div>
                    <div
                        className={clsx(
                            'download-design-container tw-flex tw-items-start tw-justify-between tw-bg-white tw-pt-[18px] sm:max-md:tw-rounded',
                        )}
                    >
                        <div
                            data-testid="design-bubbles-container"
                            className={clsx(
                                'tw-my-0 tw-text-center tw-text-xxs tw-text-map-blue-dark rtl:tw-ml-auto rtl:tw-mr-5',
                                showDownload ? 'tw-ml-5 tw-mr-auto' : 'tw-mx-auto',
                            )}
                        >
                            <div className="design-bubbles tw-mx-auto tw-my-0 tw-flex tw-w-[110px] tw-justify-between tw-pb-[10px]">
                                {availableDesignKeys!.map((designKey: ProgramAchievementGraphicDesignKey) => (
                                    <button
                                        key={designKey}
                                        type="button"
                                        aria-label={`Select ${designKey} design`}
                                        className={clsx(
                                            'design-bubble tw-m-0 tw-h-[30px] tw-w-[30px] tw-cursor-pointer tw-rounded-full tw-border-2 tw-border-solid tw-border-white tw-p-0 tw-transition-none focus:tw-outline-0',
                                            designKey,
                                        )}
                                        style={getDesignBubbleStyles(designKey)}
                                        onClick={() => handleDesignClick(designKey)}
                                        onMouseEnter={() => handleDesignHover(designKey, true)}
                                        onMouseLeave={() => handleDesignHover(designKey, false)}
                                    />
                                ))}
                            </div>
                            <div className="instructions">
                                {t('program_achievement_graphic.program_achievement_graphic.select_a_design')}
                            </div>
                        </div>
                        {showDownload && (
                            <div
                                data-testid="download-container-wrapper"
                                className="download-container-wrapper tw-mb-0 tw-ml-auto tw-mr-4 tw-mt-0 tw-pt-[1px] tw-text-[13px] tw-font-semibold tw-text-blue rtl:tw-ml-4 rtl:tw-mr-auto"
                            >
                                {downloading && (
                                    <div className="downloading-container">
                                        <span>
                                            {t('program_achievement_graphic.program_achievement_graphic.downloading')}
                                        </span>
                                        <FrontRoyalSpinner className="blue tw-mx-auto tw-my-[5px]" />
                                    </div>
                                )}
                                {!downloading && (
                                    <button
                                        className="download-container group tw-m-0 tw-flex tw-cursor-pointer tw-justify-end tw-border-0 tw-bg-transparent tw-p-0 tw-text-center tw-text-inherit tw-shadow-none"
                                        onClick={() => handleDownloadClick()}
                                        type="button"
                                    >
                                        <span className="instructions tw-mr-[7px] tw-text-[13px] tw-uppercase tw-leading-4 tw-text-blue group-hover:tw-underline rtl:tw-ml-[7px] rtl:tw-mr-0">
                                            {parseHtml(
                                                t(
                                                    'program_achievement_graphic.program_achievement_graphic.download_and_share',
                                                ),
                                            )}
                                        </span>
                                        <i className="fas fa-arrow-alt-circle-down tw-text-[30px]" />
                                    </button>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
