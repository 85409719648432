import { NotEnoughStorageError, storageSpaceAvailable } from 'storageSpaceAvailable';
import { MIN_MEGABYTES_NEEDED_TO_STORE_STREAM_CONTENT } from './constants';

// I probably would have just put this in FrontRoyalStore, but doing so led to circular dependencies
export default async function throwIfNotEnoughStorageAvailable() {
    const megabytesOfStorageAvailable = await storageSpaceAvailable();
    if (
        Number.isInteger(megabytesOfStorageAvailable) &&
        megabytesOfStorageAvailable < MIN_MEGABYTES_NEEDED_TO_STORE_STREAM_CONTENT
    ) {
        throw new NotEnoughStorageError();
    }
}
