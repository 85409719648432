import { type AnyObject } from '@Types';
import {
    type FrontRoyalStore,
    type FrontRoyalStoreDB,
    type PublishedStream,
    type PublishedPlaylist,
    type StudentDashboardContentFetchKeyParams,
    type StudentDashboardContentFetch,
} from 'FrontRoyalStore';
import { type IguanaClass } from 'Iguana';
import { type StudentDashboardContentResponse } from 'StudentDashboard';

export default async function getStudentDashboardFromStore(
    params: StudentDashboardContentFetchKeyParams,
    injector: angular.auto.IInjectorService,
) {
    const frontRoyalStore = injector.get<FrontRoyalStore>('frontRoyalStore');

    const studentDashboardContentFetch = (await frontRoyalStore.retryOnHandledError((db: FrontRoyalStoreDB) =>
        db.studentDashboardContentFetches
            .where({
                user_id: params.userId,
                cohort_id: params.cohortId,
                cohort_version_id: params.cohortVersionId,
                pref_locale: params.prefLocale,
                content_views_refresh_updated_at: params.contentViewsRefreshUpdatedAt,
            })
            .first(),
    )) as StudentDashboardContentFetch | null;
    if (studentDashboardContentFetch) {
        const Stream = injector.get('Lesson.Stream');
        const Playlist = injector.get('Playlist');

        // This logic blindly gathers up all of the streams and playlists currently in the store.
        // There's currently no way for a stream outside of the student dashboard response to be
        // included in the store, but if we ever support something like that, we may want to revisit
        // the logic in this method.
        // UPDATE: this has become a little less safe now that we're supporting
        // offline mode for users without cohorts. For cohort users, the value for `available_playlists` in
        // the student dashboard response isn't really important. We look at the cohort to get the list of playlists
        // to put on the screen. For a non-cohort user, however, we put all of the playlists from the student
        // dashboard response on the screen. There still doesn't seem to be a practical way that a user could have
        // a playlist in the database that shouldn't be on their dashboard, but if somehow there were such a playlist
        // in the database, they would see it on the screen and we would have no good way to make it go away.
        // See https://trello.com/c/2nlORqQI
        const streams = await frontRoyalStore.retryOnHandledError((db: FrontRoyalStoreDB) =>
            db.publishedStreams.toArray(),
        );
        const playlists = await frontRoyalStore.retryOnHandledError((db: FrontRoyalStoreDB) =>
            db.publishedPlaylists.toArray(),
        );

        // This method's return value is intended to look like an API response, both in structure
        // and in what it contains, so when we pull the playlists and streams from the store
        // we convert them from vanilla JSON to Iguana objects. We do this because other logic
        // expects these things to be Iguana objects.
        return {
            studentDashboardContentFetch,
            response: {
                result: [
                    {
                        lesson_streams: streams.map((stream: PublishedStream) => (Stream as IguanaClass).new(stream)),
                        available_playlists: playlists.map((playlist: PublishedPlaylist) => {
                            const attrs = { ...playlist } as AnyObject;
                            delete attrs.locale_pack_id; // avoid "property has only getter" error (see also storeStudentDashboard.js)
                            return (Playlist as IguanaClass).new(attrs);
                        }),
                    },
                ],
            } as StudentDashboardContentResponse,
        };
    }

    return null;
}
