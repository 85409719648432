import { type CurrentUserIguanaObject } from 'Users';

type AiTutorTextTest = {
    explainScreenLocale: null | string;
    reviewLocale: null | string;
    recapLocale: null | string;
    includedInTest: boolean;
};

const defaultConfig: AiTutorTextTest = {
    explainScreenLocale: null,
    reviewLocale: null,
    recapLocale: null,
    includedInTest: false,
};

const newAiTutorTextConfig: AiTutorTextTest = {
    explainScreenLocale: 'lessons.lesson.show_frames_player.chat_with_ai',
    reviewLocale: 'lessons.lesson.show_frames_player.review_with_ai',
    recapLocale: 'lessons.lesson.show_frames_player.review_with_ai',
    includedInTest: true,
};

const arbitraryThresholdDate = 1723176000000; // Friday August 9 2024

const isNewUser = (user: CurrentUserIguanaObject) =>
    new Date(user.created_at * 1000).getTime() >= arbitraryThresholdDate;

export const getAiTutorTextTest = (user: CurrentUserIguanaObject | null) => {
    if (!user) return defaultConfig;

    if (isNewUser(user)) {
        if (/^[0-7]/.test(user.id)) return defaultConfig;
        if (/^[8-f]/.test(user.id)) return newAiTutorTextConfig;
    }

    return defaultConfig;
};
