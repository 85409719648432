import { allForUser } from 'StoredProgress';

export default async function getLocalePackIdsForCompletedStreams(injector, user) {
    const frontRoyalStore = injector.get('frontRoyalStore');
    const streamProgressRecords = await frontRoyalStore.retryOnHandledError(db =>
        allForUser(db.streamProgress, user.id).toArray(),
    );
    return streamProgressRecords
        .filter(record => record.completed_at)
        .sort((a, b) => (a.completed_at < b.completed_at ? -1 : 1))
        .map(record => record.locale_pack_id);
}
