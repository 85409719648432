
        window.Smartly = window.Smartly || {};
        window.Smartly.locales = window.Smartly.locales || {};
        window.Smartly.locales.modules = window.Smartly.locales.modules || {};
        window.Smartly.locales.modules.en = window.Smartly.locales.modules.en || {};
        window.Smartly.locales.modules.en.back_royal = window.Smartly.locales.modules.en.back_royal || {}
        window.Smartly.locales.modules.en.back_royal.program_achievement_graphic = window.Smartly.locales.modules.en.back_royal.program_achievement_graphic || {};
window.Smartly.locales.modules.en.back_royal.program_achievement_graphic.program_achievement_graphic = window.Smartly.locales.modules.en.back_royal.program_achievement_graphic.program_achievement_graphic || {};
window.Smartly.locales.modules.en.back_royal.program_achievement_graphic.program_achievement_graphic = {...window.Smartly.locales.modules.en.back_royal.program_achievement_graphic.program_achievement_graphic, ...{
    "congratulations": "Congratulations",
    "you_have": "You have",
    "class_of": "Class of",
    "graduated": "Graduated",
    "completed": "Completed",
    "with_honors": "With honors",
    "awarded_degree": "and are awarded the degree of",
    "exec_ed_cert": "the Executive Education certificate",
    "select_a_design": "Select a design",
    "download_and_share": "Download<br />and Share",
    "downloading": "Downloading...",
    "you_have_been_accepted_into_the": "You have been accepted into the"
}
}
    