import { difference } from 'lodash/fp';
import logInDevMode from 'logInDevMode';
import { storageSpaceAvailable } from 'storageSpaceAvailable';
import { type ContentAccessHelperClass, type StreamIguanaClass } from 'Lessons';
import { type CurrentUserIguanaObject } from 'Users';
import { type FrontRoyalStore, type PublishedStream } from 'FrontRoyalStore';
import { type EventLogger } from 'EventLogger';
import type TimerSingleton from 'FrontRoyalTimer/TimerSingleton';
import { ALL_CONTENT_STORED } from './constants';
import throwIfNotEnoughStorageAvailable from './throwIfNotEnoughStorageAvailable';
import { getStreamAndEnsureStored } from './getStreamAndEnsureStored';

// Define the type for the parameters
type StreamStoredParams = {
    id: string; // Assuming `id` is a string, adjust as necessary
    storedStreamCount: number;
    totalCount: number;
};

// Define the function type that takes in the parameters
type OnStreamStoredFunction = (params: StreamStoredParams) => void;

function streamIsAlreadyLoaded(stream: PublishedStream, currentUserLocale: string) {
    if (!stream.all_content_stored) return false;
    if (stream.locale === currentUserLocale) return true;

    // If the user has selected a locale other than English, the stream may not be available
    // in their locale. If the stream is not available in their locale, then we expect to have
    // the English version loaded.
    const streamHasTranslationInCurrentUserLocale = stream.locale_pack.content_items.find(
        ci => ci.locale === currentUserLocale,
    );
    if (!streamHasTranslationInCurrentUserLocale && stream.locale === 'en') return true;

    // If we get here, it means that we have a fully stored version of the stream in the database,
    // but it is not for the correct locale. So, we need to reload the stream in the appropriate locale.
    return false;
}

async function getStreamsThatShouldBeLoaded({
    injector,
    streamLocalePackIds,
}: {
    injector: ng.auto.IInjectorService;
    streamLocalePackIds: string[];
}): Promise<PublishedStream[]> {
    const Stream = injector.get<StreamIguanaClass>('Lesson.Stream');
    const ContentAccessHelper = injector.get<ContentAccessHelperClass>('ContentAccessHelper');
    const frontRoyalStore = injector.get<FrontRoyalStore>('frontRoyalStore');

    // We're assuming here that any stream we might be trying to store is already
    // in the database. This should be the case, because they should have been
    // loaded up in ensureStudentDashboard.
    // We set doNotCache here to prevent `.new` from having the side effect of updating
    // the StreamCache. See https://trello.com/c/9nXkjQcW
    return (
        await frontRoyalStore.retryOnHandledError(db =>
            db.publishedStreams.where('locale_pack.id').anyOf(streamLocalePackIds).toArray(),
        )
    )
        .filter(stream => ContentAccessHelper.canLaunch(Stream.new({ ...stream, doNotCache: true })))
        .sort((a, b) =>
            streamLocalePackIds.indexOf(a.locale_pack.id) < streamLocalePackIds.indexOf(b.locale_pack.id) ? -1 : 1,
        );
}

async function logStart({
    injector,
    streamIdsToFetch,
    streamsThatShouldBeLoaded,
    streamLocalePackIds,
}: {
    injector: ng.auto.IInjectorService;
    streamIdsToFetch: string[];
    streamsThatShouldBeLoaded: PublishedStream[];
    streamLocalePackIds: string[];
}) {
    logInDevMode(injector, `loading ${streamIdsToFetch.length} of ${streamLocalePackIds.length} streams`);
    const availableSpace = await storageSpaceAvailable();
    injector.get<EventLogger>('EventLogger').log('offline_mode:loading_streams', {
        total: streamsThatShouldBeLoaded.length,
        value: streamIdsToFetch.length,
        storage_space_available: availableSpace,

        // see https://trello.com/c/iLC4Bz4p
        params: {
            screenWidth: window.innerWidth,
            screenHeight: window.innerHeight,
        },
    });
}

async function getStreamsToLoad({
    injector,
    streamLocalePackIds,
    currentUser,
}: {
    injector: ng.auto.IInjectorService;
    streamLocalePackIds: string[];
    currentUser: CurrentUserIguanaObject;
}) {
    const streamsThatShouldBeLoaded = await getStreamsThatShouldBeLoaded({ injector, streamLocalePackIds });

    const streamsThatAreAlreadyLoaded = streamsThatShouldBeLoaded.filter(stream =>
        streamIsAlreadyLoaded(stream, currentUser.pref_locale),
    );

    const streamIdsToFetch = difference(
        streamsThatShouldBeLoaded.map(s => s.id),
        streamsThatAreAlreadyLoaded.map(s => s.id),
    );

    return { streamsThatShouldBeLoaded, streamIdsToFetch };
}

async function execute(
    streamLocalePackIds: string[],
    currentUser: CurrentUserIguanaObject,
    injector: ng.auto.IInjectorService,
    {
        onStreamStored,
        abortLoadingPromise,
    }: { onStreamStored?: OnStreamStoredFunction; abortLoadingPromise: Promise<void> },
) {
    let abortLoading = false;
    abortLoadingPromise.then(() => {
        abortLoading = true;
    });

    const { streamsThatShouldBeLoaded, streamIdsToFetch } = await getStreamsToLoad({
        currentUser,
        injector,
        streamLocalePackIds,
    });

    await logStart({ injector, streamIdsToFetch, streamsThatShouldBeLoaded, streamLocalePackIds });

    for (let i = 0; i < streamIdsToFetch.length; i += 1) {
        const id = streamIdsToFetch[i];

        // FIXME:
        // There are 2 reasons we use a for loop with an await inside of it instead of
        // calling fetchAndStoreStream once for each stream and awaiting with Promise.all:
        // 1. We don't want to send out all those api requests together, since they'll lock
        //      up the queue (this wouldn't be an issue if we stopped queuing the requests,
        //      but right now that also means we would lose retry logic and, since there are
        //      potentially a bunch of requests, we'd have to be careful not to use up all the
        //      http connections that the browser allows.)
        // 2. Doing all that work at once can lock up the UI. ee https://trello.com/c/5yQMSVoI
        // This is also an issue in updateOutdatedStreams

        // eslint-disable-next-line no-await-in-loop
        await fetchAndStoreStream({ id, injector });
        if (onStreamStored) {
            onStreamStored({
                id,
                storedStreamCount: i + 1,
                totalCount: streamIdsToFetch.length,
            });
        }
        if (abortLoading) break;
    }
    return !abortLoading;
}

async function fetchAndStoreStream({ id, injector }: { id: string; injector: angular.auto.IInjectorService }) {
    await throwIfNotEnoughStorageAvailable();
    const TimerSingleton = injector.get<TimerSingleton>('timerSingleton');
    const timerKey = `offline_mode:stored_stream:${id}`;
    TimerSingleton.startTimer(timerKey);
    const frontRoyalStore = injector.get<FrontRoyalStore>('frontRoyalStore');

    const stream = await getStreamAndEnsureStored({
        id,
        ensureLessonsAndImagesStored: true,
    });
    if (stream) {
        TimerSingleton.finishTimer(timerKey, 'offline_mode:stored_stream', {
            lesson_stream_id: stream.id,
        });
    }
    frontRoyalStore.emit(ALL_CONTENT_STORED);

    return stream;
}

// NOTE: currentUserLocale has to be the current user's locale,
// because the api will use in_users_locale_or_en
export default function ensureAllContentStoredForStreams(
    streamLocalePackIds: string[],
    currentUser: CurrentUserIguanaObject,
    injector: ng.auto.IInjectorService,
    { onStreamStored }: { onStreamStored?: OnStreamStoredFunction } = {},
): { promise: Promise<boolean>; abort: () => Promise<boolean> } {
    let abortCallback;

    // This promise is going to resolve in one of two cases:
    // 1. Loading is complete
    // 2. Loading was aborted and all in-flight calls are complete
    let loadingCompleteOrAbortedPromise: Promise<boolean>;

    const abortLoadingPromise = new Promise<void>(res => {
        abortCallback = () => {
            res();
            // We want abort to return a promise that resolves only after the
            // loading has been successfully stopped
            return loadingCompleteOrAbortedPromise;
        };
    });

    loadingCompleteOrAbortedPromise = execute(streamLocalePackIds, currentUser, injector, {
        onStreamStored,
        abortLoadingPromise,
    });

    return { promise: loadingCompleteOrAbortedPromise, abort: abortCallback! };
}
