import * as userAgentHelper from 'userAgentHelper';
import 'ErrorLogging/angularModule';

import buttonCorrect from 'sounds/button_correct.mp3';
import buttonIncorrect from 'sounds/button_incorrect.mp3';
import buttonSelect from 'sounds/button_select.mp3';
import buttonScalingClickOne from 'sounds/button_scaling_click_1.mp3';
import buttonScalingClickTwo from 'sounds/button_scaling_click_2.mp3';
import buttonScalingClickThree from 'sounds/button_scaling_click_3.mp3';
import buttonScalingClickFour from 'sounds/button_scaling_click_4.mp3';
import buttonScalingClickFive from 'sounds/button_scaling_click_5.mp3';
import buttonScalingClickSix from 'sounds/button_scaling_click_6.mp3';

/*
 * Sound manager built on top of Web Audio API working draft.
 * For AudioContext polyfill info see: https://github.com/shinnn/AudioContext-Polyfill
 */
export default angular
    .module('SoundManager', ['FrontRoyal.ErrorLogService'])
    .constant('SoundConfig', {
        VALIDATE_CORRECT: buttonCorrect,
        VALIDATE_INCORRECT: buttonIncorrect,
        DEFAULT_CLICK: buttonSelect,
        SCALING_CLICKS: [
            buttonScalingClickOne,
            buttonScalingClickTwo,
            buttonScalingClickThree,
            buttonScalingClickFour,
            buttonScalingClickFive,
            buttonScalingClickSix,
        ],
    })
    .service('SoundManager', [
        '$injector',

        function ($injector) {
            const $window = $injector.get('$window');
            const $document = $injector.get('$document');
            const $http = $injector.get('$http');
            const $q = $injector.get('$q');
            const $cacheFactory = $injector.get('$cacheFactory');

            /*
             * Immediate initialization function
             */
            this.init = (() => {
                // on by default, off during tests
                this._enabled = !$window.RUNNING_IN_TEST_MODE;

                // set initial volume
                this._volume = 1;

                // create a cache for holding decoded audio buffers
                this._bufferCache = $cacheFactory('audioBuffers', {
                    number: 10,
                });

                // special Cordova / local filesystem handling
                if ($window.CORDOVA) {
                    // FIXME: this is a HACK to get lesson-player sounds working in Capacitor.
                    // This HACK is still dependent on Cordova's use of `window.cordova.file.applicationDirectory`,
                    // which will go away when we no longer have a dependency on Cordova.
                    // We should use `@capacitor-community/native-audio` going forward, but the implementation is different
                    // and would require a significant refactor of this code.
                    // See also https://trello.com/c/oYlRFpaK/1025-bug-cordova-plugin-media-not-working-in-capacitor
                    const buildDir = 'public';

                    // We see unexpected behavior with Android playing the old `file://` URLs successfully while failing with
                    // the newly expected `<ORIGIN>/_file_/` convention as described by the Ionic Webview documentation (as of 2018-10-03).
                    // Additionally, we're seeing the `_file_` scheme no longer working with `cordova-plugin-media` (as of 2019-02-21).
                    //
                    // via https://github.com/ionic-team/cordova-plugin-ionic-webview/blob/master/CHANGELOG.md#200-2018-07-23 -
                    // BREAKING: File access through the Web View must be served by the HTTP server to avoid security errors in the Web View. Loading files
                    // via `file://` is not allowed by the Web View. The HTTP server will serve files via the `_file_` prefix, e.g. `http://localhost:8080/_file_/Users/.../file.png`.
                    //
                    // see also: https://github.com/ionic-team/cordova-plugin-ionic-webview/issues/148
                    if (userAgentHelper.isiOSoriPadOSDevice()) {
                        this._webviewFileRootPath = unescape(
                            `${$window.cordova.file.applicationDirectory.replace('file://', '')}${buildDir}`,
                        );
                    } else {
                        this._webviewFileRootPath = `${$window.cordova.file.applicationDirectory}${buildDir}`;
                    }
                }

                try {
                    // standardize window API and create new AudioContext
                    $window.AudioContext = $window.AudioContext || $window.webkitAudioContext;

                    // instantiate context
                    this._context = new $window.AudioContext();

                    // we could also do any sort of preloading afterwards ...
                    // this.loadUrl('/sounds/ding.mp3');
                } catch (e) {
                    // fail 'SILENTLY' (lolz) and fallback on HTML5 audio tag
                }
            })();

            /*
             *  Enabled status
             */
            Object.defineProperty(this, 'enabled', {
                get() {
                    return this._enabled;
                },
                set(value) {
                    this._enabled = value;
                },
            });

            /*
             *  Volume control
             */
            Object.defineProperty(this, 'volume', {
                get() {
                    return this._volume;
                },
                set(value) {
                    // constrain to 0-1
                    this._volume = Math.min(1, Math.max(0, value));
                },
            });

            /*
             * Loads a buffer via a URL or cache lookup, resolving promise with buffer as completed
             */
            this.loadUrl = function (url) {
                const deferred = $q.defer();

                // check for cached buffer already, resolving promise if found
                const cacheHit = this._bufferCache.get(url);
                if (cacheHit) {
                    deferred.resolve(cacheHit);
                    return deferred.promise;
                }

                // otherwise load url, decode audio, resolving promise upon completion
                const that = this;
                const request = $http({
                    method: 'GET',
                    url,
                    responseType: 'arraybuffer',
                });

                let response;

                function retry(numTries) {
                    response = null;
                    // https://stackoverflow.com/a/27878804/1747491
                    return request.catch(() => {
                        if (numTries <= 0) {
                            return $q.reject('Reached max attempts when trying to load audio');
                        }
                        return retry(numTries - 1);
                    });
                }

                function errorCallback(error) {
                    $injector.get('ErrorLogService').notify(error, null, {
                        url,
                        byteLength: response?.data?.byteLength,
                        status: response?.status,
                        requestHeaders: response?.config?.headers,
                        responseHeaders: response?.headers(),
                    });
                }

                retry(2)
                    .then(res => {
                        response = res;
                        if (that._context) {
                            that._context.decodeAudioData(
                                response.data,
                                buffer => {
                                    that._bufferCache.put(url, buffer);
                                    deferred.resolve(buffer);
                                },
                                errorCallback,
                            );
                        } else {
                            deferred.resolve();
                        }
                    })
                    .catch(errorCallback);
                return deferred.promise;
            };

            /*
             * Plays a provided URL if an AudioContext is available and the URL is a valid sound
             */
            this.playUrl = function (url) {
                const self = this;

                if (!self._enabled) {
                    return;
                }

                if (window.CORDOVA) {
                    self.playWithMediaPlugin(url);
                    return;
                }

                // check for availability of context, defaulting to <audio>
                if (!self._context) {
                    self.playWithElement(url);
                    return;
                }

                // Since we create our AudioContext on page load, we need to call resume(), if it exists.
                // see: https://developers.google.com/web/updates/2017/09/autoplay-policy-changes#webaudio
                const promise = self._context.resume ? self._context.resume() : $q.when();
                promise.then(() => {
                    self.loadUrl(url).then(buffer => {
                        self.playBuffer(buffer);
                    });
                });
            };

            /**
             * Plays a URL using the HTML5 Audio tag
             */
            this.playWithElement = function (url) {
                // create on audio element via document
                const elem = $document[0].createElement('audio');
                const source = document.createElement('source');

                // ensure we were successful in creating the element
                if (elem && elem.play) {
                    source.src = url;

                    // set volume and play
                    elem.volume = this.volume;
                    elem.play();
                }
            };

            /**
             * Plays a URL using the Cordova Media API
             */
            this.playWithMediaPlugin = function (url) {
                let mediaPlayer;

                function cleanup() {
                    mediaPlayer.stop();
                    mediaPlayer.release();
                }

                const filePath = this._webviewFileRootPath + url;

                // Create Media object from src
                mediaPlayer = new window.Media(filePath, cleanup, cleanup);

                // Play audio
                mediaPlayer.play({
                    playAudioWhenScreenIsLocked: false,
                });
            };

            /*
             * Plays a decoded audio buffer, provided an AudioContext is available
             */
            this.playBuffer = function (buffer) {
                if (!this._context) {
                    return;
                }

                if (!this._gainNode) {
                    this._gainNode = this._context.createGain();
                }

                // prevent concurrent playback of the same buffer (causes amplitude distortion)
                if (this.currentlyPlayingSource && this.currentlyPlayingSource.buffer === buffer) {
                    try {
                        this.currentlyPlayingSource.stop(0);
                    } catch (e) {
                        // noop ... older browsers may throw DOMExceptions
                    }
                }

                // set the volume.
                this._gainNode.gain.value = this.volume;

                // create a sound source to and provide buffer
                this.currentlyPlayingSource = this._context.createBufferSource();
                const source = this.currentlyPlayingSource;
                source.buffer = buffer;

                // connect source to gain.
                source.connect(this._gainNode);

                // route source through gainNode to destination (speakers)
                this._gainNode.connect(this._context.destination);

                // start playback without delay
                source.start(0);

                // cleanup after playback has finished
                const that = this;
                source.onended = () => {
                    that.currentlyPlayingSource = undefined;
                };
            };
        },
    ]);
