import {
    type FrontRoyalStoreDB,
    type FrontRoyalStore,
    type StudentDashboardContentFetch,
    frontRoyalStoreApi,
} from 'FrontRoyalStore';
import { camelCaseStream, type StreamIguanaObject } from 'Lessons';
import { playlistIguanaObjectToCamelCase } from 'Playlists';
import { type StudentDashboardContentResponse } from 'StudentDashboard';

/*
    Determines what streams included in the student dashboard response should be stored.
    Streams that have already been stored and have `all_content_stored=1` should NOT be
    returned in this list of streams because it will result in the `all_content_stored`
    flag getting set back to 0. If not all of the content has been stored for the stream
    (i.e. `all_content_stored=0`), we can safely overwrite the existing record in the store.
    Likewise, if the stream has not already been stored, then we add it to store. That being
    said, an updated version of a stream that has `all_content_stored=1` may be included in
    the student dashboard, however, we have logic elsewhere that will update the stream
    (see `fetchOutdatedStreams` in ensureAllContentStoredForStreams.js).
*/
async function determineStreamsToStore(dashboardStreams: StreamIguanaObject[], frontRoyalStore: FrontRoyalStore) {
    const storedStreams = (
        await frontRoyalStore.retryOnHandledError((db: FrontRoyalStoreDB) =>
            db.publishedStreams.bulkGet(dashboardStreams.map(stream => stream.id)),
        )
    ).map(s => (s ? camelCaseStream(s) : s));

    return storedStreams.reduce<StreamIguanaObject[]>(
        (streamsToStore, stream, i) =>
            stream?.allContentStored ? streamsToStore : [...streamsToStore, dashboardStreams[i]],
        [],
    );
}

export default async function storeStudentDashboard(
    studentDashboardResponse: StudentDashboardContentResponse,
    studentDashboardContentFetch: StudentDashboardContentFetch,
    injector: angular.auto.IInjectorService,
) {
    const frontRoyalStore = injector.get<FrontRoyalStore>('frontRoyalStore');

    const playlists = studentDashboardResponse.result[0].available_playlists;
    const streams = studentDashboardResponse.result[0].lesson_streams;

    const streamsToStore = await determineStreamsToStore(streams, frontRoyalStore);

    await Promise.all([
        frontRoyalStoreApi.makeRequest(
            'bulkPutPublishedStreams',
            streamsToStore.map(s => camelCaseStream({ ...s.asJson(), all_content_stored: 0 })),
        ),

        // `storeStudentDashboard` should be receiving a fresh student dashboard API response,
        // so it should be safe to completely replace all playlist records here since the response
        // will contain the most up-to-date data. Also note that we need to pull the locale pack id
        // out and include it at the top level so that we can index on it (see openDb.js)
        frontRoyalStoreApi.makeRequest(
            'bulkPutPublishedPlaylists',
            playlists.map(playlistIguanaObjectToCamelCase).filter(v => !!v),
        ),
    ]);

    await frontRoyalStore.retryOnHandledError(db =>
        db.studentDashboardContentFetches.put(studentDashboardContentFetch),
    );
}
