import { ProgramAchievementGraphicDesignKey, type ProgramAchievementGraphicDesign } from './ShareableGraphics.types';

export const programAchievementGraphicDesigns: Record<
    ProgramAchievementGraphicDesignKey,
    ProgramAchievementGraphicDesign
> = {
    [ProgramAchievementGraphicDesignKey.quanticCoral]: {
        ringRGBA: { r: 255, g: 108, b: 91 },
        gradient: {
            stop1Color: '#FF6C5B',
            stop2Color: '#FF4D63',
            stop3Color: '#ED3E54',
        },
        bgGradient: {
            startColor: { hex: '#ED3E54', rgba: { r: 237, g: 62, b: 84 } },
            endColor: { hex: '#FF6C5B', rgba: { r: 255, g: 108, b: 91 } },
        },
        logoBg: '#FF4D63',
    },
    [ProgramAchievementGraphicDesignKey.quanticPurple]: {
        ringRGBA: { r: 126, g: 39, b: 255 },
        gradient: {
            stop1Color: '#4D7AFF',
            stop2Color: '#7E27FF',
            stop3Color: '#751DF8',
        },
        bgGradient: {
            startColor: { hex: '#4D7AFF', rgba: { r: 77, g: 122, b: 255 } },
            endColor: { hex: '#751DF8', rgba: { r: 117, g: 29, b: 248 } },
        },
        logoBg: '#7E27FF',
    },
    [ProgramAchievementGraphicDesignKey.quanticDarkBlue]: {
        ringRGBA: { r: 77, g: 122, b: 255 },
        gradient: {
            stop1Color: '#4D7AFF',
            stop2Color: '#0000FF',
            stop3Color: '#000080',
        },
        bgGradient: {
            startColor: { hex: '#0000FF', rgba: { r: 77, g: 122, b: 255 } },
            endColor: { hex: '#000080', rgba: { r: 0, g: 0, b: 128 } },
        },
        logoBg: '#0000FF',
    },
    [ProgramAchievementGraphicDesignKey.valarBlue]: {
        ringRGBA: { r: 77, g: 122, b: 255 },
        primaryColor: {
            hex: '#4D7AFF',
            rgba: { r: 77, g: 122, b: 255 },
        },
        bgGradient: {
            startColor: { hex: '#4D7AFF', rgba: { r: 77, g: 122, b: 255 } },
            endColor: { hex: '#4D7AFF', rgba: { r: 77, g: 122, b: 255 } },
        },
    },
    [ProgramAchievementGraphicDesignKey.valarPurple]: {
        ringRGBA: { r: 149, g: 87, b: 236 },
        primaryColor: {
            hex: '#9557EC',
            rgba: { r: 149, g: 87, b: 236 },
        },
        bgGradient: {
            startColor: { hex: '#9557EC', rgba: { r: 149, g: 87, b: 236 } },
            endColor: { hex: '#9557EC', rgba: { r: 149, g: 87, b: 236 } },
        },
    },
    [ProgramAchievementGraphicDesignKey.valarGreen]: {
        ringRGBA: { r: 29, g: 208, b: 180 },
        primaryColor: {
            hex: '#1DD0B4',
            rgba: { r: 29, g: 208, b: 180 },
        },
        bgGradient: {
            startColor: { hex: '#1DD0B4', rgba: { r: 29, g: 208, b: 180 } },
            endColor: { hex: '#1DD0B4', rgba: { r: 29, g: 208, b: 180 } },
        },
    },
};
