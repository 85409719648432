import { type AnyObject } from '@Types';
import { type auto } from 'angular';
import { type FrontRoyalStoreDB, type FrontRoyalStore } from 'FrontRoyalStore';
import { allForUser } from 'StoredProgress';
import { type CurrentUserIguanaObject } from 'Users';

const sortDescBy =
    <T extends AnyObject>(key: keyof T = 'updated_at') =>
    (a: T, b: T) => {
        if (a[key] > b[key]) return -1;
        if (b[key] > a[key]) return 1;
        return 0;
    };

// NOTE: The "active" streams refers to the "stream the user is currently studying."
export default async function getActiveStream(injector: auto.IInjectorService, user: CurrentUserIguanaObject) {
    const frontRoyalStore = injector.get<FrontRoyalStore>('frontRoyalStore');

    const lessonProgressRecords = await frontRoyalStore.retryOnHandledError((db: FrontRoyalStoreDB) =>
        allForUser(db.lessonProgress, user.id).toArray(),
    );
    const latestProgress = lessonProgressRecords.sort(sortDescBy('updated_at'))[0];
    const associatedStreams = await frontRoyalStore.retryOnHandledError(db =>
        db.publishedStreams
            .filter(({ lessons }) => !!lessons.find(({ locale_pack: { id } }) => id === latestProgress?.locale_pack_id))
            .toArray(),
    );
    const streamProgressKeys = associatedStreams.map(stream => [user.id, stream.locale_pack.id]);
    const streamProgressRecords = await frontRoyalStore.retryOnHandledError(db =>
        db.streamProgress.where('[user_id+locale_pack_id]').anyOf(streamProgressKeys).toArray(),
    );
    const mostRecentStreamProgress = streamProgressRecords.sort(sortDescBy('updated_at'))[0];
    const activeStream = associatedStreams.find(
        stream => stream.locale_pack.id === mostRecentStreamProgress?.locale_pack_id,
    );

    // If they've completed the stream, they're no longer currently studying
    // that stream, so we return null in that case.
    return mostRecentStreamProgress?.completed_at ? null : activeStream || null;
}
