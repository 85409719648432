import { shoveProgressIntoContent } from 'LessonProgress';

// Returns the list of streams that are available to be viewed offline
export default async function getOfflineStreams(injector) {
    const frontRoyalStore = injector.get('frontRoyalStore');
    const Stream = injector.get('Lesson.Stream');

    const streams = await frontRoyalStore.retryOnHandledError(db =>
        db.publishedStreams.where({ all_content_stored: 1 }).toArray(),
    );

    return shoveProgressIntoContent(
        streams.map(stream => Stream.new(stream)),
        injector.get('$rootScope').currentUser,
    );
}
