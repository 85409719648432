import { getAdmissionOffer, type CurrentUserIguanaObject } from 'Users';

type ImpactGlobalTestConfig = {
    impactLocaleOverride: string | null;
    showMonthlyPlan: boolean;
};

const NO_MONTHLY_OPTION_REPORTS_TAGS = [
    'valar_interview_optional_64_tier1+2_no_monthly',
    'valar_interview_optional_64_tier3_no_monthly',
];
const WITH_MONTHLY_OPTION_REPORTS_TAGS = ['valar_interview_optional_64_tier3_monthly'];
const INCLUDED_USERS_REPORTS_TAGS = [...NO_MONTHLY_OPTION_REPORTS_TAGS, ...WITH_MONTHLY_OPTION_REPORTS_TAGS];

const defaultConfig = (_user: CurrentUserIguanaObject): ImpactGlobalTestConfig => ({
    impactLocaleOverride: null,
    showMonthlyPlan: true,
});

const impactGlobalConfig = (user: CurrentUserIguanaObject): ImpactGlobalTestConfig => ({
    impactLocaleOverride: 'impact_global',
    showMonthlyPlan: (() => {
        const admissionOffer = getAdmissionOffer(user);
        const noMonthlyOption = NO_MONTHLY_OPTION_REPORTS_TAGS.some(tag => admissionOffer?.reportsTags?.includes(tag));

        return !noMonthlyOption;
    })(),
});

export const getImpactGlobalScholarshipTest = (user: CurrentUserIguanaObject | null) => {
    const admissionOffer = getAdmissionOffer(user);

    if (!user || !admissionOffer) return defaultConfig;

    const includedInTest = INCLUDED_USERS_REPORTS_TAGS.some(tag => admissionOffer.reportsTags.includes(tag));

    return includedInTest ? impactGlobalConfig(user) : defaultConfig(user);
};
