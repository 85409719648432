import { angularInjectorProvider } from 'Injector';
import { regionAwareImageUrlForFormat } from 'regionAwareImage';
import { type auto } from 'angular';
import { type ConfigFactory } from 'FrontRoyalConfig';
import {
    type FrontRoyalStore,
    snakeCasePublishedLessonContent,
    type CamelCasedPublishedLessonContent,
} from 'FrontRoyalStore';
import { type FrontRoyalRootScope } from 'FrontRoyalAngular';
import { getRelevantCohorts } from 'Users';
import { type LocaleAngularObject } from 'Locale';
import { lessonsApi, type Stream, type Lesson, type LessonIguanaClass, lessonToIguanaAttrs } from 'Lessons';
import getImageUrlsForLesson from './getImageUrlsForLesson';
import StoredImagesRepo from './StoredImagesRepo';

// This is a public function that can be used to load up a stream. It will first try to load the
// stream from the store, and then fall back to the http API if necessary.
export async function getStreamAndEnsureStored({
    id,
    loadFullContentForLessonId,
    ensureLessonsAndImagesStored: shouldEnsureLessonsAndImagesStored,
    forceRefetchFromLessonsApi,
}: {
    id: string;
    forceRefetchFromLessonsApi?: boolean;

    // Note that "Full content" in loadFullContentForLessonId refers to the frame content. It does not
    // do anything with images. This is used when loading up data for the lesson player.
    loadFullContentForLessonId?: string | null;

    // loadAndStoreLessonsAndImages is used when preloading content for offline mode. This ensures you
    // have frame content for all the lessons and that all the images are stored.
    ensureLessonsAndImagesStored?: boolean;
}) {
    const frontRoyalStore = angularInjectorProvider.get<FrontRoyalStore>('frontRoyalStore');
    loadFullContentForLessonId ||= null;
    shouldEnsureLessonsAndImagesStored ||= false;
    forceRefetchFromLessonsApi ||= false;

    let stream: Stream | null = null;

    if (!forceRefetchFromLessonsApi) {
        stream = await frontRoyalStore.retryRequestOnHandledError('getPublishedStream', {
            id,
            loadFullContentForLessonId,
            loadFullContentForAllLessons: shouldEnsureLessonsAndImagesStored,
        });
    }

    if (!stream) {
        stream = await getStreamFromLessonsApi({
            id,
            loadFullContentForLessonId,
            loadFullContentForAllLessons: shouldEnsureLessonsAndImagesStored,
        });

        if (stream)
            await frontRoyalStore.retryRequestOnHandledError(
                'storeStream',
                stream,
                // Wherever we're using getStreamAndEnsureStored, we want StorageQuotaExceededErrors to bubble up so we
                // can log them but prevent anything from happening in the UI. See where we catch NotEnoughStorageError
                // in OfflineModeManager and all errors in updateOutdatedStreams. Also see StoredImagesRepo, where we set the same
                // options as we do here.
                { errorHandlingPolicies: { throwOnStorageQuotaExceededError: true }, name: 'storeStream#saveRecords' },
            );
    }

    if (!stream) return null;

    if (shouldEnsureLessonsAndImagesStored) {
        stream = await ensureLessonsAndImagesStored({ stream, frontRoyalStore });
    }

    return stream;
}

async function getStreamFromLessonsApi({
    id,
    loadFullContentForLessonId,
    loadFullContentForAllLessons,
}: {
    id: string;
    loadFullContentForLessonId: string | null;
    loadFullContentForAllLessons: boolean;
}): Promise<Stream | null> {
    const currentUser = angularInjectorProvider.get<FrontRoyalRootScope>('$rootScope').currentUser;
    const Locale = angularInjectorProvider.get<LocaleAngularObject>('Locale');

    // FIXME: don't we need to forceRefetch here?
    const response = await lessonsApi.makeRequest('getPublishedStream', {
        id,
        loadFullContentForLessonId,
        loadFullContentForAllLessons,
        userCohortIds: currentUser ? getRelevantCohorts(currentUser).map(c => c.id) : null,
        prefLocale: Locale.activeCode,
    });
    const streamAttrs = response.contents?.lessonStreams[0];
    if (!streamAttrs) return null;
    const stream: Stream = {
        ...streamAttrs,
        allContentStored: 0 as const,
    };

    return stream || null;
}

async function ensureLessonsAndImagesStored({
    stream,
    frontRoyalStore,
}: {
    stream: Stream;
    frontRoyalStore: FrontRoyalStore;
}): Promise<Stream> {
    if (stream.allContentStored) return stream;
    const lessonContentRecords = await buildLessonContentRecords(stream);
    await frontRoyalStore.retryOnHandledError(db =>
        db.publishedLessonContent.bulkPut(lessonContentRecords.map(r => snakeCasePublishedLessonContent(r))),
    );
    const injector = angularInjectorProvider.injector;

    if (!injector) throw new Error('Angular injector not available');
    await storeStreamImages(stream, lessonContentRecords, injector);
    await frontRoyalStore.retryRequestOnHandledError('setAllContentStoredOnStream', stream.id);
    return {
        ...stream,
        allContentStored: 1 as const,
    };
}

// Returns PublishedLessonContent records for any lesson in the stream that has full content loaded.
// Other lessons are filtered out
export async function buildLessonContentRecords(stream: Stream<false>): Promise<CamelCasedPublishedLessonContent[]> {
    const publishedLessonContentRecords = [];
    for (let i = 0; i < stream.lessons.length; i += 1) {
        const lesson = stream.lessons[i];
        if ('frames' in lesson && lesson.frames && lesson.frames.length > 0) {
            // FIXME: we are currently doing this consecutively
            // with a delay in between
            // instead of in parallel to prevent locking up the UI,
            // even though it's awkward.  See https://trello.com/c/5yQMSVoI
            // eslint-disable-next-line no-await-in-loop
            const lessonContentRecord = await getLessonContentRecord(stream.id, lesson as Lesson<true>);
            publishedLessonContentRecords.push(lessonContentRecord);
        }
    }

    return publishedLessonContentRecords;
}

async function getLessonContentRecord(
    streamId: string,
    lesson: Lesson<true>,
): Promise<CamelCasedPublishedLessonContent> {
    const lessonContentRecord: CamelCasedPublishedLessonContent = {
        id: lesson.id,
        streamId,
        frames: lesson.frames,
        // We want to calculate isMobile and devicePixelRatio at the time of storing, so when offline
        // we can use these values to ensure we load images that are saved in the store even if
        // isMobile or devicePixelRatio has changed since then
        isMobile: window.innerWidth < 768 || window.innerHeight < 768,
        devicePixelRatio: window.devicePixelRatio,
        imageUrls: [],
    };

    const injector = angularInjectorProvider.injector;
    if (!injector) throw new Error('Angular injector not available');
    const Lesson = injector.get<LessonIguanaClass>('Lesson');
    const iguanaLesson = Lesson.new(lessonToIguanaAttrs(lesson));

    lessonContentRecord.imageUrls = await getImageUrlsForLesson(iguanaLesson);

    return lessonContentRecord;
}

async function storeStreamImages(
    stream: Stream<false>,
    lessonContentRecords: CamelCasedPublishedLessonContent[],
    injector: auto.IInjectorService,
) {
    // Fetch and store the stream image. Note that the stream
    // is a vanilla object and not an Iguana model object.
    const url = regionAwareImageUrlForFormat(
        stream.image,
        'original',
        injector.get<ConfigFactory>('ConfigFactory').getSync(true)!,
    );
    if (url) {
        const storedImagesRepo = new StoredImagesRepo([url], injector);
        await storedImagesRepo.fetchAndStoreImages();
    }

    // Fetch and store all the lesson images. We use a `for` loop here rather than
    // `Promise.all` so that lessons are "queued" up one by one instead of all at
    // once since image load requests are parallelized and doing it all at once
    // can use up all of the network connections available to the browser.
    for (let i = 0; i < lessonContentRecords.length; i++) {
        const lessonContentRecord = lessonContentRecords[i];

        const storedImagesRepo = new StoredImagesRepo(lessonContentRecord.imageUrls, injector);
        // eslint-disable-next-line no-await-in-loop
        await storedImagesRepo.fetchAndStoreImages();
    }
}
